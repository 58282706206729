<template>
    <div class="button-attachments tw-flex">
        <div class="button-attachments__button-wrapper tw-border-0 tw-border-r tw-border-solid">
            <neo-button class="button" icon="upload" color="var(--brand-color)" hoverText="var(--brand-hover-on)" :stroke="invalid ? 'var(--color-danger)' : 'var(--input-border-fade)'" padding="0.9rem 1.6rem" borderRadius="0.3rem" iconSize="lg" @click="$refs.attachmentsInput.click()" />
            <input class="tw-hidden" type="file" ref="attachmentsInput" :multiple="multiple" @change="$emit('change', $event.target.files)" />
        </div>
        <div v-if="namesList.length" class="button-attachments__list">
            <div v-for="(name, i) in namesList" :key="i" class="file tw-flex tw-justify-between tw-items-center">
                <span class="file__name tw-mr-4" :title="name">
                    {{ name }}
                </span>
                <span class="file__cross tw-cursor-pointer" @click="$emit('remove', i)" title="Remove">
                    <font-awesome-icon icon="times" />
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/components/button";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "neo-button-attachments",
    components: {
        "neo-button": Button,
        "font-awesome-icon": FontAwesomeIcon,
    },
    props: {
        fileList: {
            type: [FileList, Array],
            default: () => [],
        },
        multiple: {
            type: Boolean,
            default: () => false,
        },
        invalid: {
            type: Boolean,
            default: () => false,
        },
    },
    computed: {
        namesList() {
            return Array.from(this.fileList).map((file) => file.name);
        },
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.button-attachments {
    &__button-wrapper {
        border-color: var(--input-border-fade);
        padding-right: 2.5rem;
        margin-right: 2.5rem;
        &:last-child {
            border-right-width: 0;
        }
    }
    &__list {
        font-size: 12px;
        max-width: calc(100% - 115px - 2.5rem);
        color: #333333;
        .file {
            &__name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
</style>
